import React from 'react';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Services from '../components/Services';
import FeaturedRooms from '../components/FeaturedRooms';
import AboutUs from '../components/Noi';
import Footer from '../components/Footer';


export default function Home() {

    return (
        <>
            <Hero hero="defaultHero">
                <Banner title="Kappa house imobiliare" subtitle="Ne iubim meseria și dorim ca totul să decurgă transparent și cu zâmbetul pe buze.">
                    <Link to='/' className="theme_btn button_hover">
                        Acasa
                    </Link>
                </Banner>
            </Hero>
            <Services />
            <AboutUs />
            <FeaturedRooms />
            <Footer />

        </>
    )
}
