import React from 'react';
import { Link } from 'react-router-dom';
import Fb from '../components/Fb';
import defaultImg from '../images/room-1.jpeg'
import { FaEuroSign, FaHome, FaBed, FaWhatsapp } from 'react-icons/fa';
import PropTypes from 'prop-types';






export default function Room({ room }) {
    const { name, slug, images, price, capacity, size } = room;


    return (



        <div className="container">

            <div className="row">

                <div className="r_card">

                    <div className="r_card_header img-fluid"
                        style={{
                            backgroundImage: `url(${images[0] || defaultImg})`
                        }}

                    >

                        {/* <img src={images[0] || defaultImg} alt="" className="r_card_header" style={{ width: '18rem' }} /> */}
                    </div>

                    <div className="r_card_content">


                        <div className="room-info">
                            {name}
                        </div>
                        <div className="text_info">
                            <Link to={`/rooms/${slug}`} className="theme_btn button_hover"> vezi anunt
                </Link>
                            <div className="text_info_shade" />
                        </div>
                    </div>
                    <div className="price_info">
                        <span className="price_w">Pret:</span>
                        <span className="price_v">{price} <FaEuroSign /></span>
                    </div>
                    <div className="r_card_footer">
                        <div className="icon area">
                            <FaHome aria-hidden="true" /> {size} m<sup>2</sup>
                        </div>
                        <div className="icon room">
                            <FaBed aria-hidden="true" /> {capacity}
                        </div>
                        <div className="icon bath">
                            <Fb />

                        </div>



                    </div>
                    <small className="text-muted">Last updated 10 mins ago</small>

                </div>
            </div>
            {/* whatsapp */}
            <a href="https://wa.me/+40740973915" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
            </a>
        </div>

    )
}


Room.propTypes = {
    room: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        price: PropTypes.number.isRequired,
    })

}