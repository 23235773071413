import React from 'react'
import Hero from '../components/Hero';
import Banner from "../components/Banner";
import { Link } from 'react-router-dom';
import RoomContainer from '../components/RoomContainer';
import Footer from '../components/Footer';


const Rooms = () => {


    return (
        <>
            <Hero hero='roomsHero'>
            
                <Banner title="Apartamente-Case-Terenuri" subtitle="Comercial">
                    <Link to='/' className="theme_btn button_hover">
                        Acasa
                 </Link>
                </Banner>
               
            </Hero>
            <RoomContainer />
             
            <Footer />
        </>

    );
};

export default Rooms;