import React, { Component } from 'react'
import defaultBcg from '../images/room-1.jpeg';
// import Hero from '../components/Hero';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import { RoomContext } from '../context';
import StyledHero from '../components/StyledHero';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Footer from '../components/Footer';
// import Agent from '../components/Agent';
// import AgentUnu from '../components/AgentUnu';

export default class SingleRoom extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props);
        this.state = {
            slug: this.props.match.params.slug,
            defaultBcg,
            photoIndex: 0,
            isOpen: false,
        };
    }


    static contextType = RoomContext;

    // componentDidMount() {

    // }






    render() {
        const { getRoom } = this.context;
        const room = getRoom(this.state.slug);
        if (!room) {
            return <div className="error">
                <h3>camera negasita</h3>
                <Link to='/rooms' className="btn-primary">
                    Apartamente recomandate
               </Link>
            </div>
        }





        const {
            name,
            description,
            capacity,
            size,
            price,
            extras,
            credit,
            cash,
            images,
            photos
        } = room;


        const popupImages = photos.map(photo => {
            return photo.fields.file.url
        });

        const { photoIndex, isOpen } = this.state;

        console.log(photos)

        const [mainImg, ...defaultImg] = images;
        return (

            <>
                <StyledHero img={mainImg || this.state.defaultBcg}>
                    <Banner title={`${name}`}>
                        <Link to='/rooms' className='btn-primary'>
                            Apartamente
                </Link>
                    </Banner>

                </StyledHero>

                <div className="photos">
                    {/* {photos.map((item, index) => {
                        return <div key={index} className="photo">
                            <img src={item.fields.file.url} alt={cash} />
                        </div>
                    })} */}


                    <div>


                        {isOpen && (
                            <Lightbox
                                mainSrc={popupImages[photoIndex]}
                                nextSrc={popupImages[(photoIndex + 1) % popupImages.length]}
                                prevSrc={popupImages[(photoIndex + popupImages.length - 1) % popupImages.length]}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + popupImages.length - 1) % popupImages.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % popupImages.length,
                                    })
                                }
                            />
                        )}
                    </div>


                </div>



                <section className='single-room'>

                    <div className='single-room-images'>
                        {defaultImg.map((item, index) => {
                            return <img key={index} src={item} alt={name} />
                        })}
                    </div>




                    <div className='single-room-info'>
                        <article className='desc'>
                            <h3>Descriere anunt</h3>
                            <p className="text-lg-left mt-3 mb-3">{description}</p>
                        </article>
                        <article className='info text-justify'>
                            <h3>Prezentare generala</h3>
                            <h6>pret: <span>&#8364;</span>{price}</h6>
                            <h6>dimensiune: {size} <sup>m2</sup> </h6>
                            <h6>
                                nr camere : {
                                    capacity > 1 ? `${capacity} camere` : `${capacity} camere`
                                }

                            </h6>
                            <h6>
                                {cash ? "plata cash" :
                                    "nu se accepta plata cash"}
                            </h6>

                            <h6>
                                {credit && "se accepta credit"}
                            </h6>
                            <div className="row justify-content-end">

                            </div>

                        </article>
                    </div>




                </section>

                <section className="room-extras">
                    <h6>Dotari</h6>

                    <button className="theme_btn button_hover" type="button" onClick={() => this.setState({ isOpen: true })}>
                        Galerie imagini
        </button>
                    <ul className="extras">
                        {extras.map((item, index) => {
                            return <li key={index}>{item}</li>
                        })}
                    </ul>




                </section>


                <Footer />
            </>
        );
    }
}
