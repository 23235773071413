import React from 'react'

import { FaWhatsapp } from 'react-icons/fa';


export default function Servicii() {
    return (
        <section className="about-section spad">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12">
                        <div className="section-title">

                            <span className="text-primary">Let us guide you home</span>
                            <h2 className="text-dark">Consultanta juridica gratuita<span>.</span></h2>
                            <p className="text-dark">Agenția noastră oferă servicii gratuite de consultanță și asistență juridică dacă vrei să cumperi, să vinzi sau să închiriezi un imobil. Îți venim în ajutor, îți explicăm și te asistăm pas cu pas pe parcursul tranzacției, îți oferim soluții juridice pentru orice problemă întâmpinată, pregătim și întocmim dosarul notarial, dosarul de credit și tot ce este necesar în vederea îndeplinirii formalităților după semnarea contractului de vânzare. Nu te costă nimic, ne dorim să îți reducem timpii și eforturile pentru încheierea tranzacției imobiliare..</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-text">
                                    <h4 className="text-dark">Despre Kappa-house-imobiliare</h4>
                                    <p className="text-dark">Acasa – locul unde zi de zi ai parte de momente de neuitat. Ne dorim sa iubesti „acasa” si de aceea am creat kappaimobiliare, pentru ca tu sa iti poti gasi rapid casa pe care ti-o doresti.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-text">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* whatsapp */}
            <a href="https://wa.me/+40740973915" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
            </a>
        </section>


    )
}


