import React, { Component } from 'react';
// import items from './data';
import Client from './Contentful';



const RoomContext = React.createContext();
class RoomProvider extends Component {

    state = {
        rooms: [],
        sortedRooms: [],
        featuredRooms: [],
        loading: true,
        type: 'toate',
        capacity: 1,
        price: 0,
        minPrice: 0,
        maxPrice: 0,
        minSize: 0,
        maxSize: 0,
        credit: false,
        cash: false
    };

    //getData

    getData = async () => {
        try {
            let response = await Client.getEntries({
                content_type: "kappaImobiliare",
                order: "sys.createdAt"
                // order: "-fields.price"
            });

            console.log(response)

            let rooms = this.formatData(response.items);
            // console.log(rooms);
            let featuredRooms = rooms.filter(room => room.featured === true);

            let maxPrice = Math.max(...rooms.map(item => item.price));
            let maxSize = Math.max(...rooms.map(item => item.size));



            this.setState({
                rooms,
                featuredRooms,
                sortedRooms: rooms,
                loading: false,
                price: maxPrice,
                maxPrice,
                maxSize,
            });

        } catch (error) {
            console.log(error)
        }
    }


    // lifecycle method

    componentDidMount() {
        this.getData()
    }

    formatData(items) {
        let tempItems = items.map(item => {
            let id = item.sys.id;
            let images = item.fields.images.map(image => image.fields.file.url);
            let room = { ...item.fields, images, id };
            return room;
        });

        return tempItems;
    }

    //get room method
    getRoom = (slug) => {
        let tempRooms = [...this.state.rooms];
        const room = tempRooms.find((room) => room.slug === slug);
        return room;
    };


    //method
    handleChange = event => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = event.target.name

        this.setState({
            [name]: value
            // callback function
        }, this.filterRooms)

        //console.log(`this is type: ${type}, this is name: ${name},this is value: ${value}`);
    }

    //method

    filterRooms = () => {
        let {
            rooms, type, capacity, price, minSize, maxSize, credit, cash
        } = this.state
        // all the rooms
        let tempRooms = [...rooms];
        // transform values
        capacity = parseInt(capacity);
        price = parseInt(price);

        // filter by type
        if (type !== 'toate') {
            tempRooms = tempRooms.filter(room => room.type === type)
        }

        //filter by capacity
        if (capacity !== 1) {
            tempRooms = tempRooms.filter(room => room.capacity >= capacity)
        }

        // filter by price

        tempRooms = tempRooms.filter(room => room.price <= price);
        //filter by  size
        tempRooms = tempRooms.filter(room => room.size >= minSize && room.size <= maxSize);

        //filter by credit
        if (credit) {
            tempRooms = tempRooms.filter(room => room.credit === true);
        }
        //filter by cash
        if (cash) {
            tempRooms = tempRooms.filter(room => room.cash === true);
        }
        console.log(credit);


        //change state
        this.setState({
            sortedRooms: tempRooms
        })
    }

    render() {
        return (
            <RoomContext.Provider value={{ ...this.state, getRoom: this.getRoom, handleChange: this.handleChange }}>
                {this.props.children}
            </RoomContext.Provider>
        )
    }
}

const RoomConsumer = RoomContext.Consumer;


export function withRoomConsumer(Component) {
    return function ConsumerWrapper(props) {
        return <RoomConsumer>
            {value => <Component {...props} context={value} />}
        </RoomConsumer>
    }
}



export { RoomProvider, RoomConsumer, RoomContext };