import React from 'react';
import ShareLink from 'react-facebook-share-link';
import { FaFacebookF } from 'react-icons/fa';



export default function Fb() {



    return (
        <div>
            <div className="fb-share-button">
                <ShareLink link='https://www.kappa-house-imobiliare.com'>
                    {link => (
                        <a href={link} target='_blank' >
                            <FaFacebookF />
                        </a>
                    )}
                </ShareLink>
            </div>
        </div>
    )
}
