import React from 'react';
import { useContext } from 'react';
import { RoomContext } from '../context';
import { FaEuroSign, FaWhatsapp } from 'react-icons/fa';
import Title from '../components/Title';


// get all unique values

const getUnique = (items, value) => {
    return [...new Set(items.map(item => item[value]))]
}


export default function RoomsFilter({ rooms }) {

    const context = useContext(RoomContext);

    const {
        handleChange, type, capacity, price, minPrice, maxPrice, minSize, maxSize, credit, cash
    } = context;

    // get unique types

    let types = getUnique(rooms, 'type');

    // add all

    types = ['toate', ...types];

    //map to jsx

    types = types.map((item, index) => {
        return <option value={item} key={index}> {item} </option>
    })

    let people = getUnique(rooms, 'capacity');
    people = people.map((item, index) => {
        return < option key={index} value={item}>{item}</option>
    })

    return (
        <section className="filter-container">
            <Title title="Cauta apartamente" />
            <form className="filter-form">

                {/*select type*/}
                <div className="form-group">
                    <label htmlFor="type">Locatie</label>
                    <select name="type"
                        id="type"
                        value={type}
                        className="form-control"
                        onChange={handleChange}>
                        {types}
                    </select>
                </div>
                {/*end select type*/}

                {/*select oaspeti*/}
                <div className="form-group">
                    <label htmlFor="capacity">Numar camere</label>
                    <select name="capacity"
                        id="capacity"
                        value={capacity}
                        className="form-control"
                        onChange={handleChange}>
                        {people}
                    </select>
                </div>
                {/*end select oaspeti*/}



                {/*pret camera oaspeti*/}

                <div className="form-group">
                    <label htmlFor="price">
                        Pret apartament: {price}<FaEuroSign />
                    </label>

                    <input type="range"
                        name="price"
                        min={minPrice}
                        max={maxPrice}
                        id="price"
                        value={price}
                        onChange={handleChange}
                        className="form-control" />
                </div>
                {/*end pret camera oaspeti*/}


                {/* size */}
                <div className="form-group">
                    <label htmlFor="size">Marime camera</label>
                    <div className="size-inputs">

                        <input type="number"
                            name="minSize"
                            id="size"
                            value={minSize}
                            onChange={handleChange}
                            className="size-input" />

                        <input type="number"
                            name="maxSize"
                            id="size"
                            value={maxSize}
                            onChange={handleChange}
                            className="size-input" />
                    </div>
                </div>
                {/* end size */}

                {/*extras */}

                <div className="form-group">
                    <div className="single-extra">
                        <input type="checkbox"
                            name="credit"
                            id="credit"
                            checked={credit}
                            onChange={handleChange}
                        />
                        <label htmlFor="credit">Credit</label>
                    </div>

                    <div className="single-extra">
                        <input type="checkbox"
                            name="cash"
                            id="cash"
                            checked={cash}
                            onChange={handleChange}
                        />
                        <label htmlFor="cash">Cash</label>
                    </div>

                </div>




                {/* end of extras*/}

            </form>
            {/* whatsapp */}
            <a href="https://wa.me/+40740973915" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
            </a>
        </section>
    )
}
