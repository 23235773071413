import React from 'react';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom'
import Footer from '../components/Footer';

export default function Error() {
    return (
        <>
        <Hero hero="defaultHero">
            <Banner title="404" subtitle="pagina nu exista">
                    <Link to='/' className="theme_btn button_hover">
                    Acasa
                    </Link>
            </Banner>
        </Hero>
       <Footer/>
</>
    )
}



