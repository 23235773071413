import React from 'react';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

import Servicii from '../components/Servicii';



export default function SRV() {

    return (
        <>
            <Hero hero="defaultHero">
                <Banner title="Servicii" subtitle="Consultanta juridica gratuita">
                    <Link to='/' className="theme_btn button_hover">
                        Acasa
                    </Link>
                </Banner>
            </Hero>
         
            
            <Servicii />
            
            <Footer/>
        </>
    )
}
