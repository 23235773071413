import React from 'react';
import { BsFillPersonFill, BsBuilding, BsPhone, BsChatDots} from 'react-icons/bs';
import ImgAgent from '../images/octav.jpg';
import { Card } from 'react-bootstrap'
export default function Agent() {
    return (
        // <div className="col-ml-auto aside">
        //     <div className="author-card">
        //         <div className="author-img set-bg ">
        //             <img src={ImgAgent} alt="octavian ursu agent imobiliar" />
        //         </div>
        //         <div className="author-info">
        //             <h5> <BsFillPersonFill /> Octavian Ursu</h5>
        //             <p className="agent"> Agent imobiliar <BsBuilding /> </p>
        //         </div>
        //         <div className="author-contact">
        //             <p><a href="tel:40-755-242840"><BsPhone /> Telefon : (40) 755 242 840</a></p>
        //             <p><a href="mailto:octavian.ursu84@yahoo.com?Subject=Hello%20again" target="_top"><BsChatDots /> E-mail: octavian.ursu84@yahoo.com</a></p>
        //         </div>
        //     </div>

        // </div>


        <article>
            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={ImgAgent} />
                <Card.Body style={{ color: '#007bff' }}>
                    Agent imobiliar <BsBuilding />
                    <Card.Title style={{ color: '#007bff' }}>
                        <BsFillPersonFill />Octavian Ursu
                </Card.Title>
                    <Card.Text>
                        <a href="tel:40-755-242840"><BsPhone /> Telefon : (40) 755 242 840</a>
                    </Card.Text>
                  
                    <BsChatDots />      <a href="mailto:octavian.ursu84@yahoo.com?Subject=Hello%20again" target="_top">
                        E-mail: octavian.ursu84@yahoo.com </a>
                </Card.Body>
            </Card>
        </article>




    )
}
