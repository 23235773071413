import React from 'react';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';



import Contact from '../components/Contact'


import Footer from '../components/Footer';



export default function SRV() {

    return (
        <>
            <Hero hero="defaultHero">
                <Banner title="Contact" subtitle="Kappa imobiliare">
                    <Link to='/' className="theme_btn button_hover">
                        Acasa
                    </Link>
                </Banner>
            </Hero>
         

            <Contact />
            <Footer />
            
        </>
    )
}
