import React from 'react'
import Agent from '../components/Agent';
import AgentUnu from '../components/AgentUnu';
import { FaWhatsapp } from 'react-icons/fa';

export default function Contact() {
    return (
        <section className="about-section spad center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="section-title text-center">
                            <h2 className="echipa">Echipa <span className="echipa">Kappa Imobiliare</span>  </h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-text">
                                    <Agent />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-text">
                                    <AgentUnu />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* whatsapp */}
            <a href="https://wa.me/+40740973915" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
            </a>
        </section>
    )
}