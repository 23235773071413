import React from 'react'
import { FaLocationArrow, FaFacebookF, FaTwitter, FaDribbble, FaBehance, FaPhone, FaMapMarkerAlt, FaRegEnvelopeOpen } from 'react-icons/fa';


export default function Footer() {
    return (
        <article>
            {/* // < !--Site footer-- > */}
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <h6>Kappa-house-imobiliare</h6>
                            <p className="text-justify">Kappa-house-imobiliare <i className="acasa"> Acasa </i> locul unde zi de zi ai parte de momente de neuitat.Ne dorim sa iubesti „acasa” si de aceea am creat Kappa-house-imobiliare, pentru ca tu sa iti poti gasi rapid casa pe care ti-o doresti.</p>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <h6>Adresa</h6>
                            <ul className="footer-links">
                                <li>   <FaMapMarkerAlt />
                                    Miroslava  700531
                                    Iasi</li>
                                <li> <FaPhone /><a href="tel:+40 748 069 483"> 0740973915</a></li>
                                <li><FaPhone /><a href="tel:+40 755 242 840"> 0755242840</a></li>
                                <li><FaRegEnvelopeOpen /><a href="mailto:office@kappa-imobiliare.com"> kappaimobiliare@yahoo.com</a></li>
                                <li><FaRegEnvelopeOpen /><a href="mailto:octavian.ursu84@yahoo.com"> octavian.ursu84@yahoo.com</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <h6>Newsletter</h6>
                            <ul className="footer-links">
                                <div id="mc_embed_signup">
                                    <form action="https://kappa-imobiliare.us19.list-manage.com/subscribe/post?u=6f5f6639f834784fe94ef3dfc&id=fae19ee4e4" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                        <div className="input-group d-flex flex-row">
                                            <input name="EMAIL"
                                                placeholder="adresa e-mail"

                                                required type="email" />
                                            <button className="btn btn-info"> <FaLocationArrow /></button>
                                        </div>
                                        <div className="mt-10 info" />
                                    </form>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">Copyright © 2020 All Rights Reserved by
                                <a href="#"> Kappa-house-imobiliare</a> | Design by <a href="https://www.tandreig.com" target="_blank" rel="opener">TAG </a>
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="social-icons">
                                <li><a className="facebook" href="#"><FaFacebookF /></a></li>
                                <li><a className="twitter" href="#"><FaTwitter /></a></li>
                                <li><a className="dribbble" href="#"><FaDribbble /></a></li>
                                <li><a className="linkedin" href="#"><FaBehance /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </article>

    );
}