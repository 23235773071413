import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import DespreImg from '../images/homelogo1.svg';

export default function Noi() {
    return (
        <section className="about_history_area section_gap">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d_flex align-items-center">
                        <div className="about_content ">
                            <h2 className="title title_color">Despre noi <br />Istoria noastra<br />Misiunea &amp; viziunea noastra.
                </h2>
                            <p className="desprentxt">Dispunem de cele mai noi oferte imobiliare din toate zonele Iasului.
                  <b>COMISION 0%</b> pentru apartamentele din blocurile noi. <br />
                  Venim în sprijinul dumneavoastră cu orice informaţie necesară, chiar şi în relaţia
                  notarială / bancară, în vederea încheierii contractului;<br />
                            </p><hr />
                            <p>Modalitatile de plata ale oricaruia dintre apartamentele de vanzare noi din Iasi sunt
                            numeroase, astfel incat
                            fiecare viitor cumparator sa o poata alege pe cea mai convenabila. Dezvoltatorul
                            imobiliar va pune la dispozitie
                            plata Cash, prin credit ipotecar, credit “Prima Casa”, precum si printr-un
                  parteneriat cu UNICREDIT ori BCR.</p>
                            <p>Toate proiectele noastre rezidentiale sunt localizate in zone care sa va permita sa
                            aveti acces rapid la mijloace de
                            transport in comun, parcuri, centre comerciale, supermarketuri, zone de agrement,
                            spalatorii, dar si multe alte
                  locatii utile, pe care sa le puteti folosi zilnic.</p>
                            <b>PROFESIONALISM si SIGURANTA</b> in tranzactiile imobiliare.<br />
                            <p />

                        </div>
                    </div>
                    <div className="col-md-6">

                        <img className="img-fluid" src={DespreImg} alt="despre noi" />

                        <h6>Serviciile oferite:</h6>
                        <ul className="desprenoi">
                            <li> <FaCheckCircle></FaCheckCircle> intermedierea de vanzari &amp;
                  cumparari &amp; inchirieri:
                  apartamente, spatii comerciale,
                  case,
                  terenuri</li>
                            <li> <FaCheckCircle></FaCheckCircle> consiliere financiara,
                  juridica,bancara</li>
                            <li> <FaCheckCircle></FaCheckCircle> perfectare documente</li>
                            <li> <FaCheckCircle></FaCheckCircle> consultanta proiecte,
                  investitii, oportunitati</li>
                            <li> <FaCheckCircle></FaCheckCircle> promovare imobiliara</li>
                            <li> <FaCheckCircle></FaCheckCircle> intabulare si cadastru</li>
                            <li> <FaCheckCircle></FaCheckCircle> certificate energetice</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    );
}