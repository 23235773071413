import React, { Component } from 'react';
import { FaHome, FaBuilding, FaBox, FaCity } from "react-icons/fa";
import Title from './Title';

export default class Services extends Component {
    state = {
        services: [
            {
                icon: <FaBuilding />,
                title: 'Apartamente',
                info: 'Dispunem de un portofoliu bogat de apartamente noi si vechi in toate zonele din Iasi.'
            },
            {
                icon: <FaHome />,
                title: 'Case',
                info: 'Casa ta mult visata poate fi aici. Cauta in ofertele noastre ceea ce ti se potriveste cel mai bine.'
            },
            {
                icon: <FaBox />,
                title: 'terenuri',
                info: 'Intra si gaseste oferta care ti se potriveste. Avem un portofoliu bogat si actualizat de terenuri de vanzare.'
            },
            {
                icon: <FaCity />,
                title: 'comercial',
                info: 'Vrei sa iti dezvolti afacerea? Gaseste in lista noastra de spatii comerciale, de birouri si industriale, proprietatea care ti se potriveste. '
            }
        ]
    }

    render() {
        return (
            <section className="services">
                <Title title='Servicii imobiliare de top' />
                <div className="services-center">

                    {this.state.services.map((item, index) => {
                        return <article key={index} className="service">
                            <span>{item.icon}</span>
                            <h6>{item.title}</h6>
                            <p>{item.info}</p>
                        </article>
                    })}

                </div>
            </section>
        )
    }
}
