import React, { Component } from 'react';
import logo from '../images/logo1.svg';
import { FaAlignRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../App.css';



export default class NavBar extends Component {
    state = {
        isOpen: false
    }
    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    handleHide = () => {
        this.setState({
            isOpen: false
        })
    }

    render() {
        return (
            <nav className="navbar bg-darks fixed-top navbar-expand-xs bg-light justify-content-center" >
                <img src={logo} className="navbar-brand" alt="kappa imobiliare" />
                <div className="nav-center">
                    <div className="nav-header">
                        <Link className="guide" to="/">

                        </Link>

                        <button type="button" className="nav-btn" onClick={this.handleToggle} onBlur={this.handleHide}>
                            <FaAlignRight className="nav-icon" />

                        </button>
                    </div>
                    <ul className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}>
                        <li>
                            <Link to="/">Acasa</Link>
                        </li>
                        <li>
                            <Link to="/Rooms">Apartamente</Link>
                        </li>

                        <li>
                            <Link to="/Servicii">Servicii</Link>
                        </li>
                        <li>
                            <Link to="/Contact">Contact</Link>
                        </li>
                    </ul>
                </div>

            </nav>
        )
    }
}
